export const formatDate = (dateString: string) => {
  console.log(dateString);
  const date = new Date(dateString);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  console.log('timeZone', timeZone);
  return date.toLocaleString('es-ES', {
    timeZone: timeZone,
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};
