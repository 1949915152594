import { Warning } from '@mui/icons-material';
import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { EventList } from './components/EvenList';
import { MessageInput } from './components/MessageInput';
import { WorkflowEventProvider } from './context/WorkflowEventProvider';
import { useWorkflowEvents } from './hooks/useWorkflowEvents';

interface WorkflowEventsProps {
  nombreCandidato: string;
  phone_number: string;
  zerosAdded: number;
}

interface WorkflowEventsBodyProps {
  nombreCandidato: string;
  phone_number: string;
}

const WorkflowEvents: React.FC<WorkflowEventsProps> = ({
  nombreCandidato,
  phone_number,
  zerosAdded,
}) => {
  const phone = phone_number.replace(/[^0-9]+/g, '');
  const phoneWithoutZeros = phone.slice(0, phone.length - zerosAdded);

  return (
    <WorkflowEventProvider>
      <WorkflowEventsBody nombreCandidato={nombreCandidato} phone_number={phoneWithoutZeros} />
    </WorkflowEventProvider>
  );
};

const WorkflowEventsBody = ({ nombreCandidato, phone_number }: WorkflowEventsBodyProps) => {
  const { events, isLoading, error } = useWorkflowEvents(phone_number);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);
  const [canSendMessages, setCanSendMessages] = useState(false);

  useEffect(() => {
    // If not events or events is undefined, return
    if (!events) return;

    // Find las message with origin 'user'
    const lastUserMessage = events
      .slice()
      .reverse()
      .find((event) => event.origin === 'user');

    // If not lastUserMessage, return
    if (!lastUserMessage) return;

    // Check if the last message was sent in the last 24 hours
    const lastMessageDate = new Date(lastUserMessage.createdAt);
    const now = new Date();

    if (now.getTime() - lastMessageDate.getTime() < 24 * 60 * 60 * 1000) {
      setCanSendMessages(true);
    } else {
      setCanSendMessages(false);
    }
  }, [events]);

  const isNearBottom = (element: HTMLDivElement) => {
    const threshold = 80; // píxeles de margen
    const position = element.scrollHeight - element.scrollTop - element.clientHeight;
    return position < threshold;
  };

  const handleScroll = () => {
    if (scrollRef.current) {
      setShouldAutoScroll(isNearBottom(scrollRef.current));
    }
  };

  useEffect(() => {
    if (scrollRef.current && shouldAutoScroll) {
      const scrollOptions = {
        top: scrollRef.current.scrollHeight,
        behavior: 'smooth' as ScrollBehavior,
      };
      scrollRef.current.scrollTo(scrollOptions);
    }
  }, [events, shouldAutoScroll]);

  return (
    <Box sx={{ height: '80vh', display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          flex: 1,
          bgcolor: 'background.default',
          height: '100%',
        }}
      >
        {isLoading ? (
          <Box
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
          >
            <CircularProgress />
          </Box>
        ) : error ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              gap: 2,
              flexDirection: 'column',
            }}
          >
            <Warning sx={{ fontSize: 40 }} color='error' />
            <Typography variant='h6'>Error fetching workflow events</Typography>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: '100%' }}>
            <Box
              ref={scrollRef}
              onScroll={handleScroll}
              sx={{ overflow: 'auto', flex: 1, height: '100%', px: 4 }}
            >
              <EventList events={events} />
            </Box>
            <MessageInput
              nombreCandidato={nombreCandidato}
              canSendMessages={canSendMessages}
              phone_number={phone_number}
              onSendMessage={() => setShouldAutoScroll(true)}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default WorkflowEvents;
