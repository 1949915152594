const HUNTER_AI_URL = process.env.REACT_APP_HUNTER_AI_URL;
const HUNTER_AI_API_KEY = process.env.REACT_APP_HUNTER_AI_API_KEY;

export const sendGreetingTemplate = async (phoneNumber: string, name: string) => {
  const url = `${HUNTER_AI_URL}/webhook/whatsapp/send-saludo`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'api-key-auth': HUNTER_AI_API_KEY,
    },
    body: JSON.stringify({
      phone_number: phoneNumber,
      name: name,
    }),
  });

  const data = await response.json();

  return data;
};

export type BotStatusResponse = {
  enabled: boolean;
};

export const fetchBotStatus = async (phoneNumber: string): Promise<BotStatusResponse> => {
  const url = new URL(`${HUNTER_AI_URL}/webhook/whatsapp/bot/status`);
  url.searchParams.append('phone_number', phoneNumber);

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'api-key-auth': HUNTER_AI_API_KEY,
    },
  });

  const data = await response.json();

  console.log(data);

  return data;
};

export const disableBot = async (phoneNumber: string): Promise<BotStatusResponse> => {
  const url = new URL(`${HUNTER_AI_URL}/webhook/whatsapp/lock`);

  await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'api-key-auth': HUNTER_AI_API_KEY,
    },
    body: JSON.stringify({
      phoneNumber,
    }),
  });

  return {
    enabled: false,
  };
};

export const enableBot = async (phoneNumber: string): Promise<BotStatusResponse> => {
  const url = new URL(`${HUNTER_AI_URL}/webhook/whatsapp/unlock`);

  await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'api-key-auth': HUNTER_AI_API_KEY,
    },
    body: JSON.stringify({
      phoneNumber,
    }),
  });

  return {
    enabled: true,
  };
};
