import getAxios from 'apiRestConnector';
import { RequestInterface } from 'components/models/RequestInterfaces';
import React, { FC, useState, useEffect, useContext } from 'react';
import { AuthContext } from 'services/authContext/AuthContextProvider';
import { Autocomplete, Box, Chip, TextField } from '@mui/material';

import { DateTime } from 'luxon';

interface Props {
  estado: boolean;
  solicitudCandidate: number[];
  setSolicitudCandidate: (t: number[]) => void;
}

const RequestComponent: FC<Props> = ({ estado, solicitudCandidate, setSolicitudCandidate }) => {
  interface req {
    label: string;
    id: number;
    indice: number;
    stack: string;
    fechaEstimadaFin: string;
    prioridad: string;
  }

  const [template, setTemplate] = useState<RequestInterface[]>([]);
  const [templateDataSelected, setTemplateDataSelected] = useState<RequestInterface[]>([]);
  const { storeAuth } = useContext(AuthContext)!;
  const { access_token } = storeAuth.user;
  const { REACT_APP_BACKEND_URL } = process.env;
  const [optionRequest, setOptionRequest] = useState<req[]>([]);
  const [valueRequest, setValueRequest] = useState<req[]>([]);

  useEffect(() => {
    const urlBackendAbierta = `${REACT_APP_BACKEND_URL}/solicitudes/estado/Abierto`;
    const urlBackendCerrada = `${REACT_APP_BACKEND_URL}/solicitudes/estado/Cerrado`;

    Promise.all([
      getAxios.instance('').get(urlBackendAbierta, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }).catch(() => ({ data: [] })),
      getAxios.instance('').get(urlBackendCerrada, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }).catch(() => ({ data: [] })),
    ])
      .then(([openResult, closeResult]) => {
        const openRequest = openResult.data;
        const closeRequest = closeResult.data;
        const requests = [...openRequest, ...closeRequest];
        setTemplate(requests);
        setTemplateDataSelected(requests);
      })
      .catch((error) => {
        console.error('error:', error.message);
      });
  }, [access_token, REACT_APP_BACKEND_URL, estado]);

  useEffect(() => {
    if (Object.keys(template).length !== 0) {
      let array = [''];
      setOptionRequest(
        template
          .filter((d: RequestInterface) => !solicitudCandidate.includes(d.id))
          .map((data: RequestInterface, index: number) => {
            return {
              label: data.rol + ' ' + data.nivel + ' ' + data.empresa + ' ' + data.responsable,
              prioridad: data.prioridad,
              fechaEstimadaFin: data.fechaEstimadaFin,
              id: data.id,
              indice: index,
              stack: data.stack.map((s: string) => s).join(),
            };
          })
          .filter((solicitud: req, i: number) => {
            if (array.every((a: string) => !a.includes(solicitud.label + solicitud.stack))) {
              array.push(solicitud.label + solicitud.stack);
              return solicitud;
            } else {
              return '';
            }
          })
          .sort((c1: any, c2: any) => {
            let factorC1 =
              c1.prioridad === 'Reemplazo'
                ? 0.2
                : c1.prioridad === 'Nuevo colaborador en nuevo cliente'
                  ? 0.5
                  : c1.prioridad === 'Nuevo colaborador'
                    ? 0.8
                    : c1.prioridad === 'Alta'
                      ? 0.9
                      : c1.prioridad === 'Media'
                        ? 0.9
                        : c1.prioridad === 'Baja'
                          ? 0.9
                          : 0.9;
            let factorC2 =
              c2.prioridad === 'Reemplazo'
                ? 0.2
                : c2.prioridad === 'Nuevo colaborador en nuevo cliente'
                  ? 0.5
                  : c2.prioridad === 'Nuevo colaborador'
                    ? 0.8
                    : c2.prioridad === 'Alta'
                      ? 0.9
                      : c2.prioridad === 'Media'
                        ? 0.9
                        : c2.prioridad === 'Baja'
                          ? 0.9
                          : 0.9;
            if (
              DateTime.fromISO(c1.fechaEstimadaFin).valueOf() >= DateTime.now().valueOf() &&
              DateTime.fromISO(c2.fechaEstimadaFin).valueOf() >= DateTime.now().valueOf()
            ) {
              return ((new Date(c1.fechaEstimadaFin).getTime() - new Date().getTime()) /
                (1000 * 60 * 60 * 24)) *
                factorC1 <
                ((new Date(c2.fechaEstimadaFin).getTime() - new Date().getTime()) /
                  (1000 * 60 * 60 * 24)) *
                  factorC2
                ? -1
                : 1;
            } else if (
              DateTime.fromISO(c1.fechaEstimadaFin).valueOf() < DateTime.now().valueOf() &&
              DateTime.fromISO(c2.fechaEstimadaFin).valueOf() >= DateTime.now().valueOf()
            ) {
              return 1;
            } else if (
              DateTime.fromISO(c1.fechaEstimadaFin).valueOf() >= DateTime.now().valueOf() &&
              DateTime.fromISO(c2.fechaEstimadaFin).valueOf() < DateTime.now().valueOf()
            ) {
              return -1;
            } else {
              return DateTime.fromISO(c1.fechaEstimadaFin).valueOf() >
                DateTime.fromISO(c2.fechaEstimadaFin).valueOf()
                ? -1
                : 1;
            }
          })
      );
    }
    if (Object.keys(templateDataSelected).length !== 0) {
      setValueRequest(
        templateDataSelected
          .filter((data: RequestInterface) => solicitudCandidate.includes(data.id))
          .sort((c1: any, c2: any) => {
            let factorC1 =
              c1.prioridad === 'Reemplazo'
                ? 0.2
                : c1.prioridad === 'Nuevo colaborador en nuevo cliente'
                  ? 0.5
                  : c1.prioridad === 'Nuevo colaborador'
                    ? 0.8
                    : c1.prioridad === 'Alta'
                      ? 0.9
                      : c1.prioridad === 'Media'
                        ? 0.9
                        : c1.prioridad === 'Baja'
                          ? 0.9
                          : 0.9;
            let factorC2 =
              c2.prioridad === 'Reemplazo'
                ? 0.2
                : c2.prioridad === 'Nuevo colaborador en nuevo cliente'
                  ? 0.5
                  : c2.prioridad === 'Nuevo colaborador'
                    ? 0.8
                    : c2.prioridad === 'Alta'
                      ? 0.9
                      : c2.prioridad === 'Media'
                        ? 0.9
                        : c2.prioridad === 'Baja'
                          ? 0.9
                          : 0.9;
            if (
              DateTime.fromISO(c1.fechaEstimadaFin).valueOf() >= DateTime.now().valueOf() &&
              DateTime.fromISO(c2.fechaEstimadaFin).valueOf() >= DateTime.now().valueOf()
            ) {
              return ((new Date(c1.fechaEstimadaFin).getTime() - new Date().getTime()) /
                (1000 * 60 * 60 * 24)) *
                factorC1 <
                ((new Date(c2.fechaEstimadaFin).getTime() - new Date().getTime()) /
                  (1000 * 60 * 60 * 24)) *
                  factorC2
                ? -1
                : 1;
            } else if (
              DateTime.fromISO(c1.fechaEstimadaFin).valueOf() < DateTime.now().valueOf() &&
              DateTime.fromISO(c2.fechaEstimadaFin).valueOf() >= DateTime.now().valueOf()
            ) {
              return 1;
            } else if (
              DateTime.fromISO(c1.fechaEstimadaFin).valueOf() >= DateTime.now().valueOf() &&
              DateTime.fromISO(c2.fechaEstimadaFin).valueOf() < DateTime.now().valueOf()
            ) {
              return -1;
            } else {
              return DateTime.fromISO(c1.fechaEstimadaFin).valueOf() >
                DateTime.fromISO(c2.fechaEstimadaFin).valueOf()
                ? -1
                : 1;
            }
          })
          .map((d: RequestInterface, index: number) => {
            return {
              label: d.rol + ' ' + d.nivel + ' ' + d.empresa + ' ' + d.responsable,
              id: d.id,
              indice: index,
              fechaEstimadaFin: d.fechaEstimadaFin,
              prioridad: d.prioridad,
              stack: d.stack.map((s: string) => s).join(),
            };
          })
      );
    }
  }, [template, solicitudCandidate, templateDataSelected]);

  const colorHeader = (priori: string, fechaEstiFin: string) => {
    let factor1 =
      priori === 'Reemplazo'
        ? 0.2
        : priori === 'Nuevo colaborador en nuevo cliente'
          ? 0.5
          : priori === 'Nuevo colaborador'
            ? 0.8
            : priori === 'Alta'
              ? 0.9
              : priori === 'Media'
                ? 0.9
                : priori === 'Baja'
                  ? 0.9
                  : 0.9;
    let diffDias =
      (new Date(fechaEstiFin).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24);
    return DateTime.fromISO(fechaEstiFin).valueOf() < DateTime.now().valueOf()
      ? '#B8C6C6'
      : diffDias * factor1 > 0 && diffDias * factor1 <= 7
        ? '#cc4646'
        : diffDias * factor1 > 7 && diffDias * factor1 <= 14
          ? '#EBAA39'
          : diffDias * factor1 > 14
            ? '#5EAB39'
            : '#B8C6C6';
  };

  return (
    <Box>
      <Autocomplete
        multiple
        filterSelectedOptions
        key={'requestAuto'}
        id='rol'
        size='medium'
        sx={{ display: 'flex', width: '100%', margin: 'auto', color: `${colorHeader}` }}
        options={optionRequest}
        value={valueRequest}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setSolicitudCandidate(newValue);
          } else if (newValue.length > 0) {
            setSolicitudCandidate(newValue.map((v: any) => v.id));
          } else {
            setSolicitudCandidate(newValue.map((v: any) => v.id));
          }
        }}
        freeSolo
        renderOption={(props, option: req) => (
          <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            <Box
              component='span'
              sx={{
                width: 14,
                height: 14,
                flexShrink: 0,
                borderRadius: '3px',
                mr: 1,
                mt: '2px',
              }}
              style={{ backgroundColor: colorHeader(option.prioridad, option.fechaEstimadaFin) }}
            />
            {option.label}
          </Box>
        )}
        renderTags={(value: readonly req[], getTagProps) =>
          value.map((option: req, index: number) => (
            <Chip
              {...getTagProps({ index })}
              key={option.id}
              variant='outlined'
              onClick={() => {
                window.open(`/request/${option.id}/editar`);
              }}
              label={option.label}
              style={{ borderBlockColor: colorHeader(option.prioridad, option.fechaEstimadaFin) }}
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} label='Solicitud' placeholder='Ingrese una solicitud' />
        )}
      />
      <div className='div-solicitud'></div>
    </Box>
  );
};

export default RequestComponent;
