import { EmojiHandFilled } from '@fluentui/react-icons';
import PsychologyIcon from '@mui/icons-material/Psychology';
import SendIcon from '@mui/icons-material/Send';
import { Box, IconButton, TextField, Tooltip } from '@mui/material';
import React from 'react';
import { useWorkflowEvents } from '../hooks/useWorkflowEvents';

export const MessageInput = ({
  nombreCandidato,
  canSendMessages,
  phone_number,
  onSendMessage,
}: {
  nombreCandidato: string;
  canSendMessages: boolean;
  phone_number: string;
  onSendMessage: () => void;
}) => {
  const {
    messageInput,
    setMessageInput,
    sendMessage,
    botEnabled,
    enableBot,
    disableBot,
    sendGreeting,
  } = useWorkflowEvents(phone_number);

  const submit = () => {
    onSendMessage();
    sendMessage();
  };

  const sendGreetingSubmit = () => {
    onSendMessage();
    sendGreeting(nombreCandidato);
  };

  return (
    <Box
      sx={{
        px: 4,
        py: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyItems: 'center',
        bgcolor: 'background.paper',
        width: '100%',
        gap: 2,
      }}
    >
      <TextField
        disabled={!canSendMessages}
        id='message-input'
        variant='outlined'
        placeholder='Enviar mensaje al candidato'
        value={messageInput}
        onChange={(e) => setMessageInput(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            submit();
          }
        }}
        fullWidth
      />
      {canSendMessages && (
        <Tooltip title='Enviar mensaje'>
          <IconButton
            color='primary'
            aria-label='enviar mensaje'
            sx={{ p: 2 }}
            onClick={() => submit()}
            disabled={messageInput.trim() === '' || !canSendMessages}
          >
            <SendIcon />
          </IconButton>
        </Tooltip>
      )}
      {!canSendMessages && (
        <Tooltip title='Enviar plantilla de saludo'>
          <IconButton
            color='primary'
            aria-label='enviar mensaje'
            sx={{ p: 2 }}
            onClick={() => sendGreetingSubmit()}
            disabled={canSendMessages}
          >
            <EmojiHandFilled />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={botEnabled ? 'Bot está activo' : 'Bot está desactivado'}>
        <IconButton
          aria-label='desbloquear bot'
          onClick={() => (botEnabled ? disableBot() : enableBot())}
          color={botEnabled ? 'success' : 'default'}
          sx={{
            '&:hover': {
              backgroundColor: botEnabled ? 'rgba(46, 125, 50, 0.04)' : 'rgba(0, 0, 0, 0.04)',
            },
          }}
        >
          {botEnabled ? <PsychologyIcon /> : <PsychologyIcon />}
        </IconButton>
      </Tooltip>
    </Box>
  );
};
